import http from '@/../src/api/http.js'
import store from '../store/index.js'
function CheckAuthority(page,callBack)
{
	http.get('Base/CheckAuthority', {
		page
	}, true).then(rslt => {
		if(callBack!=null)
			callBack(rslt)
	})
}

// 同步本地缓存的用户信息
function SynCacheInfo(callBack) {
	http.get("/Student/GetClientInfo", {}, true).then((res) => {		
		if (res.code == 1) {
			let userInfo = res.data.UserInfo
			let xunKeType = res.data.XuanKeType
			let model = res.data.Model
			store.commit("setUserInfo", userInfo)
			store.commit("set_XuanKeType", xunKeType)
			store.commit("set_GKModel", model)
			let obj = {}
			obj.AreaID = userInfo.AreaID
			obj.AreaName = userInfo.AreaName
			store.commit("set_UserSetArea", obj)
			store.commit("set_StuTags", res.data.Tags);
		} else {
			ElMessage.error(res.msg)
		}
		if (callBack != null)
			callBack()
	})
}
function IsCanViewJieDu() {
	let isCan = false
	if (this.IsPeiXuan())
		isCan = true
	return isCan
}
//是否为家庭培训版用户
function IsPeiXuan() {
	let val = 0
	let uInfo = store.getters.getUserInfo()
	if (uInfo) {
		val = uInfo.IsPeixun
	}
	return val
}
export default {
	CheckAuthority,
	SynCacheInfo,
	IsCanViewJieDu,
	IsPeiXuan
}